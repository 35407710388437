import TaxInputs from "../interfaces/TaxInputs";

export default function getTaxInputs(taxYearStart: number): TaxInputs | undefined {

    switch (taxYearStart) {
        case 2021: {
            return {
                corporationTaxRates: [
                    {rate: 0.19}
                ],
                personalAllowance: {
                    allowance: 12570.0,
                    threshold: 100000.0,
                    taperFactor: 0.5
                },
                dividendAllowance: 2000.0,
                savingsAllowances: [
                    {threshold: 12570.0, allowance: 5000.0, taperFactor: 1.0},
                    {threshold: 37700.0, allowance: 1000.0},
                    {threshold: 150000.0, allowance: 500},
                ],
                propertyIncomeAllowance: 0.0,
                capitalGainsAllowance: 12300,
                taxRates: [
                    {threshold: 37700, rate: 0.2}, // basic
                    {threshold: 150000, rate: 0.4}, // higher
                    {rate: 0.45} // additional
                ],
                dividendRates: [
                    {threshold: 37700, rate: 0.075}, // basic
                    {threshold: 150000, rate: 0.325}, // higher
                    {rate: 0.381} // additional
                ],
                capitalGainsRedidentialPropertyRates: [
                    {threshold: 37700, rate: 0.18}, // basic
                    {rate: 0.28}, // higher and additional
                ],
                capitalGainsOtherRates: [
                    {threshold: 37700, rate: 0.1}, // basic
                    {rate: 0.2}, // higher and additional
                ],
                niEmployerRates: [
                    {threshold: 170 * 52, rate: 0.0},
                    {rate: 0.138},
                ],
                niClass1Rates: [
                    {threshold: 184 * 52, rate: 0.0},
                    {threshold: 967 * 52, rate: 0.12},
                    {rate: 0.02},
                ],
                niClass2Rates: [
                    {threshold: 12570, rate: 0.0},
                    {rate: 0.0, fixedAmount: 3.45 * 52},
                ],
                niClass4Rates: [
                    {threshold: 12570, rate: 0.0},
                    {threshold: 50270, rate: 0.09},
                    {rate: 0.02,}
                ]
            };
        }

        case 2022: {
            return {
                corporationTaxRates: [
                    {rate: 0.19}
                ],
                personalAllowance: {
                    allowance: 12570.0,
                    threshold: 100000.0,
                    taperFactor: 0.5
                },
                dividendAllowance: 2000.0,
                savingsAllowances: [
                    {threshold: 12570.0, allowance: 5000.0, taperFactor: 1.0},
                    {threshold: 37700.0, allowance: 1000.0},
                    {threshold: 150000.0, allowance: 500},
                ],
                propertyIncomeAllowance: 0.0,
                capitalGainsAllowance: 12300,
                taxRates: [
                    {threshold: 37700, rate: 0.2}, // basic
                    {threshold: 150000, rate: 0.4}, // higher
                    {rate: 0.45} // additional
                ],
                dividendRates: [
                    {threshold: 37700, rate: 0.0875}, // basic
                    {threshold: 150000, rate: 0.3375}, // higher
                    {rate: 0.3935} // additional
                ],
                capitalGainsRedidentialPropertyRates: [
                    {threshold: 37700, rate: 0.18}, // basic
                    {rate: 0.28}, // higher and additional
                ],
                capitalGainsOtherRates: [
                    {threshold: 37700, rate: 0.1}, // basic
                    {rate: 0.2}, // higher and additional
                ],
                niEmployerRates: [
                    {threshold: 175 * 52, rate: 0.0},
                    {rate: 0.1505},
                ],
                niClass1Rates: [
                    {threshold: 11908, rate: 0.0},
                    {threshold: 967 * 52, rate: 0.1325},
                    {rate: 0.0325},
                ],
                niClass2Rates: [
                    {threshold: 12570, rate: 0.0},
                    {rate: 0.0, fixedAmount: 3.45 * 52},
                ],
                niClass4Rates: [
                    {threshold: 12570, rate: 0.0},
                    {threshold: 50270, rate: 0.09},
                    {rate: 0.02,}
                ]
            };
        }

        case 2023: {
            return {
                corporationTaxRates: [
                    {threshold: 50000, rate: 0.19},
                    {threshold: 250000, rate: 0.265},
                    {rate: 0.25}
                ],
                personalAllowance: {
                    threshold: 100000.0,
                    allowance: 12570.0,
                    taperFactor: 0.5
                },
                dividendAllowance: 1000.0,
                savingsAllowances: [
                    {threshold: 12570.0, allowance: 5000.0, taperFactor: 1.0},
                    {threshold: 37700.0, allowance: 1000.0},
                    {threshold: 125140.0, allowance: 500},
                ],
                propertyIncomeAllowance: 0.0,
                capitalGainsAllowance: 12300,
                taxRates: [
                    {threshold: 37700, rate: 0.2}, // basic
                    {threshold: 125140, rate: 0.4}, // higher
                    {rate: 0.45} // additional
                ],
                dividendRates: [
                    {threshold: 37700, rate: 0.0875}, // basic
                    {threshold: 125140, rate: 0.3375}, // higher
                    {rate: 0.3935} // additional
                ],
                capitalGainsRedidentialPropertyRates: [
                    {threshold: 37700, rate: 0.18}, // basic
                    {rate: 0.28}, // higher and additional
                ],
                capitalGainsOtherRates: [
                    {threshold: 37700, rate: 0.1}, // basic
                    {rate: 0.2}, // higher and additional
                ],
                niEmployerRates: [
                    {threshold: 175 * 52, rate: 0.0},
                    {rate: 0.138},
                ],
                niClass1Rates: [
                    {threshold: 11908, rate: 0.0},
                    {threshold: 967 * 52, rate: 0.12},
                    {rate: 0.02},
                ],
                niClass2Rates: [
                    {threshold: 12570, rate: 0.0},
                    {rate: 0.0, fixedAmount: 3.45 * 52},
                ],
                niClass4Rates: [
                    {threshold: 12570, rate: 0.0},
                    {threshold: 50270, rate: 0.09},
                    {rate: 0.02}
                ]
            };
        }

        default:
            return undefined;
    }
}
