import IncomeInputs from "../interfaces/IncomeInputs";
import TaxAllowance from "../interfaces/TaxAllowance";
import TaxInputs from "../interfaces/TaxInputs";
import TaxRate from "../interfaces/TaxRate";

export type TraderType = "LimitedCompany" | "SoleTrader";

export type CalculateTaxInputs = IncomeInputs & TaxInputs & {
    profit?: number; // from limited comapny or sole trader
    salary?: number;  // from limited company (sole trader salary is always 100% profits)
};

export interface CalculateTaxOptions {
    tradingAs?: TraderType;
    excludeIncomeTax?: boolean;
    excludeCapitalGainsTax?: boolean;
    excludeDividendTax?: boolean;
    excludeEmployerNI?: boolean;
    excludeClass1NI?: boolean;
    excludeClass2NI?: boolean;
    excludeClass4NI?: boolean;
    excludeCorporationTax?: boolean;
}

export interface TaxComputationBand {
    taxBand: [number, number]
    rate: number
    income: number
    tax: number
    note?: string
}

export interface TaxComputationResult {
    tradingAs: TraderType,
    salary: number,
    grossDividend: number,
    netDividend: number,
    totalSalary: number,
    totalDividends: number,
    propertyIncome: number,
    savingsIncome: number,
    personalAllowance: number,
    totalIncome: number,
    taxedIncome: number,
    foreignTaxesPaid: number;
    incomeTaxDue: number,
    dividendTaxDue: number,
    salaryTaxBands: TaxComputationBand[],
    propertyTaxBands: TaxComputationBand[],
    savingsTaxBands: TaxComputationBand[],
    dividendTaxBands: TaxComputationBand[],
    capitalGainsTaxResidentialProperty: number,
    capitalGainsTotal: number,
    capitalGainsTaxOther: number,
    capitalGainsTaxDue: number,
    capitalGainsTaxResidentialPropertyBands: TaxComputationBand[],
    capitalGainsTaxOtherBands: TaxComputationBand[],
    employerNI: number,
    class1NI: number,
    class2NI: number,
    class4NI: number,
    totalNI: number,
    exmployerNIBands: TaxComputationBand[],
    class1NIBands: TaxComputationBand[],
    class2NIBands: TaxComputationBand[],
    class4NIBands: TaxComputationBand[],
    corporationTaxDue: number,
    corporationTaxBands: TaxComputationBand[],
    corporationTaxRate: number,
    corporationTaxSalarySaving: number,
    totalCompanyCost: number,
    totalCompanyTax: number,
    totalTax: number,
    afterTaxReceipt: number,
    effectiveTaxRate: number
}

interface CalculateTaxResult {
    taxDue: number,
    taxedIncome: number,
    taxBands: TaxComputationBand[]
}

function _calculateTax(args: {
    untaxedIncome?: number,
    maxUntaxedIncome?: number,  // maxUntaxedIncome and maxGrossIncome can be set together when the untaxedIncome isn't known but within a range
    maxGrossIncome?: number,
    taxedIncome: number,
    maxPersonalAllowance: number,
    personalAllowance: number,
    extraAllowance: number,
    maxExtraAllowance?: number,
    extraAllowanceName?: string,
    basicRateIncrease: number,
    taxRates: TaxRate[]}): CalculateTaxResult {
    let {untaxedIncome, maxUntaxedIncome, maxGrossIncome, taxedIncome, maxPersonalAllowance, personalAllowance, extraAllowance, maxExtraAllowance, extraAllowanceName, basicRateIncrease, taxRates} = args;

    if (untaxedIncome !== undefined && maxGrossIncome !== undefined) {
        throw Error("untaxedIncome and maxGrossIncome should not both be set.");
    }

    let usedUntaxedIncome: number = (untaxedIncome ?? maxUntaxedIncome) ?? 0;
    let usedMaxGrossIncome = maxGrossIncome ?? Infinity;

    if (untaxedIncome === 0) {
        return {taxDue: 0, taxBands: [], taxedIncome}
    }

    let taxDue = 0.0;
    let taxBands: TaxComputationBand[] = []

    // tax free allowance
    const allowanceUsed = Math.min(extraAllowance, usedUntaxedIncome, usedMaxGrossIncome);
    if (allowanceUsed > 0) {
        usedUntaxedIncome -= allowanceUsed;
        usedMaxGrossIncome -= allowanceUsed;
        taxedIncome += allowanceUsed;
        taxDue += 0;
        taxBands.push({
            taxBand: [0, extraAllowance],
            rate: 0,
            income: allowanceUsed,
            tax: 0,
            note: (maxExtraAllowance && maxExtraAllowance !== extraAllowance)
                    ? `${extraAllowanceName} reduced from £${maxExtraAllowance.toFixed()} to £${extraAllowance.toFixed()}`
                    : undefined
        });
    }

    // personal allowance
    const personalAllowanceUsed = Math.max(Math.min(personalAllowance + extraAllowance - taxedIncome, usedUntaxedIncome, usedMaxGrossIncome), 0);
    if (maxPersonalAllowance) {
        usedUntaxedIncome -= personalAllowanceUsed;
        usedMaxGrossIncome -= personalAllowanceUsed;
        taxedIncome += personalAllowanceUsed;
        taxDue += 0;
        taxBands.push({
            taxBand: [extraAllowance, personalAllowance + extraAllowance],
            rate: 0,
            income: personalAllowanceUsed,
            tax: 0,
            note: maxPersonalAllowance !== personalAllowance ? `Personal allowance reduced from £${maxPersonalAllowance.toFixed()} to £${personalAllowance.toFixed()}` : undefined
        });
    }

    // process each tax band (tax rates are sorted by threshold)
    let prevThreshold = allowanceUsed + personalAllowanceUsed;
    taxRates.forEach((taxRate, idx) => {
        let threshold = taxRate.threshold !== undefined 
            ? (taxRate.threshold + personalAllowance)
            : taxedIncome + usedUntaxedIncome;

        // Apply any increase to the basic rate threshold
        let bandIncrease = 0;
        if (taxRate.rate > 0 && basicRateIncrease > 0) {
            bandIncrease = basicRateIncrease;
            threshold += basicRateIncrease;
            basicRateIncrease = 0;

            const nextThreshold = taxRates[idx+1]?.threshold;
            if (!!nextThreshold && threshold > nextThreshold) {
                bandIncrease -= threshold - nextThreshold;
                threshold = nextThreshold;
            }
        }

        let incomeInBand = Math.max(Math.min(usedUntaxedIncome + taxedIncome, threshold) - taxedIncome, 0)
        if (incomeInBand > 0) {
            let taxInBand = (incomeInBand * taxRate.rate) + (taxRate.fixedAmount ?? 0);

            // If the income + tax in this band pushes us over the max gross income then we have to cap the income
            if (incomeInBand + taxInBand > usedMaxGrossIncome) {
                incomeInBand = (usedMaxGrossIncome - (taxRate.fixedAmount ?? 0)) / (1 + taxRate.rate);
                taxInBand = (incomeInBand * taxRate.rate) + (taxRate.fixedAmount ?? 0);

                // Cap the taxed income so we don't add any more bands
                usedUntaxedIncome = incomeInBand;
            }

            usedUntaxedIncome -= incomeInBand;
            usedMaxGrossIncome -= incomeInBand + taxInBand;
            taxedIncome += incomeInBand;
            taxDue += taxInBand;
            taxBands.push({
                taxBand: [prevThreshold, threshold],
                rate: taxRate.rate,
                income: incomeInBand,
                tax: taxInBand,
                note: bandIncrease !== 0 ? `Tax band increased by £${bandIncrease.toFixed(2)}.` : undefined
            });
        }

        prevThreshold = threshold
    });


    return {taxDue, taxedIncome, taxBands};
}

function _calculateInverseTax(args: {postTaxIncome: number, taxRates: TaxRate[]}) {
    let {postTaxIncome, taxRates} = args;

    let taxDue = 0.0;
    let taxBands: TaxComputationBand[] = []

    if (postTaxIncome === 0) {
        return {taxDue: 0, taxBands: []}
    }

    let untaxedPostTaxIncome = postTaxIncome;

    let prevThreshold = 0;
    taxRates.forEach((taxRate, idx) => {
        let threshold = taxRate.threshold !== undefined ? taxRate.threshold : Infinity;

        // Threshold after corporation tax is removed
        const postTaxThreshold = threshold * (1 - taxRate.rate) - (taxRate.fixedAmount ?? 0);
        const postTaxIncomeInBand = Math.min(postTaxThreshold, untaxedPostTaxIncome);
        untaxedPostTaxIncome -= postTaxIncomeInBand;

        if (postTaxIncomeInBand > 0) {
            const incomeInBand = (postTaxIncomeInBand / (1 - taxRate.rate)) + (taxRate.fixedAmount ?? 0);
            const taxInBand = (incomeInBand * taxRate.rate) + (taxRate.fixedAmount ?? 0); 
            taxDue += taxInBand;

            taxBands.push({
                taxBand: [prevThreshold, threshold],
                rate: taxRate.rate,
                income: incomeInBand,
                tax: taxInBand,
                note: undefined
            });
        }

        prevThreshold = threshold
    });


    return {taxDue, taxBands}
}


function _calculateAllowance(totalTaxableIncome: number, allowances: TaxAllowance[]) {
    // Calculate all allowances and return the max. This covers cases where there can be a couple that might apply, for
    // example the savings starter rate that tapers to 0 before the basic rate allowance kicks in which can be claimed
    // instead.
    return allowances.map((allowance) => {

    let personalAllowance = allowance?.allowance ?? 0;
    const personalAllowanceThreshold = allowance?.threshold ?? 0;
    const maxPersonalAllowance = personalAllowance;

    if (totalTaxableIncome > personalAllowanceThreshold) {
        if (allowance?.taperFactor) {
            const taperFactor = allowance?.taperFactor ?? 0;
            personalAllowance -= Math.min((totalTaxableIncome - personalAllowanceThreshold) * taperFactor, personalAllowance);
        }
        else {
            personalAllowance = 0;
        }
    }

    return [personalAllowance, maxPersonalAllowance];
    })
    .sort((a, b) => b[0] - a[0])[0] ?? [0, 0];
}


export default function calculateTax(args: CalculateTaxInputs, options?: CalculateTaxOptions): TaxComputationResult & CalculateTaxOptions {
    // Calculate the employee national insurance paid on only the salary from our limited company
    const tradingAs = options?.tradingAs ?? "LimitedCompany";

    const excludeClass1NI = options?.excludeClass1NI ?? false;
    const excludeCorporationTax = options?.excludeCorporationTax || tradingAs !== "LimitedCompany";
    const excludeEmployerNI = options?.excludeEmployerNI || tradingAs !== "LimitedCompany";
    const excludeClass2NI = options?.excludeClass2NI || tradingAs !== "SoleTrader";
    const excludeClass4NI = options?.excludeClass4NI || tradingAs !== "SoleTrader";

    let employerNi: CalculateTaxResult = {taxDue: 0, taxBands: [], taxedIncome: 0};
    let class1Ni: CalculateTaxResult = {taxDue: 0, taxBands: [], taxedIncome: 0};
    let class2Ni: CalculateTaxResult = {taxDue: 0, taxBands: [], taxedIncome: 0};
    let class4Ni: CalculateTaxResult = {taxDue: 0, taxBands: [], taxedIncome: 0};
    let corporationTax: CalculateTaxResult = {taxDue: 0, taxBands: [], taxedIncome: 0};

    let salary = args.salary ?? 0;
    let totalSalary: number = 0;
    let grossDividend: number = 0;
    let netDividend: number = 0;
    let corporationTaxRate: number = 0;
    let corporationTaxSalarySaving: number = 0;

    // NI gets computed separately for sole traders
    if (tradingAs === "SoleTrader") {
        // Sole traders pay Class 1 NIC on salary from other jobs
        if (!excludeClass1NI) {
            class1Ni = _calculateTax({
                untaxedIncome: args.otherSalary ?? 0,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.niClass1Rates ?? []
            });
        }

        // And Class 2 and 4 on sole trader profits
        if (!excludeClass2NI) {
            class2Ni = _calculateTax({
                untaxedIncome: args.profit ?? 0,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.niClass2Rates ?? []
            });
        }
    
        if (!excludeClass4NI) {
            class4Ni = _calculateTax({
                untaxedIncome: args.profit ?? 0,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.niClass4Rates ?? []
            });
        }

        // Include all profit and other salary in total salary
        totalSalary = (args.profit ?? 0) + (args.otherSalary ?? 0);
    }
    else {
        // Limited companies pay employer NIC contributions
        if (!excludeEmployerNI) {
            employerNi = _calculateTax({
                maxUntaxedIncome: salary,
                maxGrossIncome: args.profit ?? 0,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.niEmployerRates ?? []
            });

            // The salay paid can be less than the maxUntaxedIncome
            salary = employerNi.taxedIncome;
        }
    
        // Total salary is the salary paid from the company plus any other employments
        totalSalary = salary + (args.otherSalary ?? 0);

        // And the employee pays Class 1 NICs
        if (!excludeClass1NI) {
            class1Ni = _calculateTax({
                untaxedIncome: totalSalary,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.niClass1Rates ?? []
            });
        }

        // And the company pays corporation tax on the rest of the profits
        grossDividend = Math.max((args.profit ?? 0) - salary - employerNi.taxDue, 0);

        if (!excludeCorporationTax) {
            corporationTax = _calculateTax({
                untaxedIncome: grossDividend,
                taxedIncome: 0,
                maxPersonalAllowance: 0,
                personalAllowance: 0,
                extraAllowance: 0,
                basicRateIncrease: 0,
                taxRates: args.corporationTaxRates ?? []
            });

            if (grossDividend !== args.profit ?? 0) {
                // Calculate the corporation tax saving by paying some profit as salary
                const corporationTaxOnAllProfit = _calculateTax({
                    untaxedIncome: args.profit ?? 0,
                    taxedIncome: 0,
                    maxPersonalAllowance: 0,
                    personalAllowance: 0,
                    extraAllowance: 0,
                    basicRateIncrease: 0,
                    taxRates: args.corporationTaxRates ?? []
                });

                corporationTaxSalarySaving = corporationTaxOnAllProfit.taxDue - corporationTax.taxDue;
            }

            corporationTaxRate = grossDividend !== 0 ? (corporationTax.taxDue / grossDividend) : 0.0;
        }

        // Anything left over can be paid as a dividend
        netDividend = grossDividend - corporationTax.taxDue;
    }

    // Calculate the total cost to the company for salary, dividends and NI
    const totalCompanyTax = corporationTax.taxDue + employerNi.taxDue;
    const totalCompanyCost = grossDividend + salary + employerNi.taxDue;

    // Calculate the total employee income
    const totalTaxableIncome =
        salary +
        (args.otherSalary ?? 0) +
        (args.otherDividends ?? 0) +
        (args.savingsIncome ?? 0) +
        (args.propertyIncome ?? 0) + 
        (args.capitalGainsRedidentialProperty ?? 0) +
        (args.capitalGainsOther ?? 0) +
        netDividend;

    // Reduce the personal allowance for income over the personal allowance threshold
    const [personalAllowance, maxPersonalAllowance] = _calculateAllowance(totalTaxableIncome,
        args.personalAllowance ? [args.personalAllowance] : []);

    const [savingsAllowance, maxSavingsAllowance] = _calculateAllowance(totalTaxableIncome,
        args.savingsAllowances ?? []);

    let taxedIncome = 0.0;
    let incomeTaxDue = 0.0;
    let dividendTaxDue = 0.0;
    let capitalGainsTaxDue = 0.0;

    let totalDividends = netDividend + (args.otherDividends ?? 0);

    // Calculate tax on total salary first and update taxed income
    const salaryTax = _calculateTax({
        untaxedIncome: totalSalary,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: 0,
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.taxRates ?? []
    })

    taxedIncome += totalSalary;
    incomeTaxDue += salaryTax.taxDue;

    // Add tax from property income
    const propertyIncomeTax = _calculateTax({
        untaxedIncome: args.propertyIncome ?? 0,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: args.propertyIncomeAllowance ?? 0,
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.taxRates ?? []
    })

    taxedIncome += args.propertyIncome ?? 0;
    incomeTaxDue += propertyIncomeTax.taxDue;

    // Add tax from savings interest income
    const savingsIncomeTax = _calculateTax({
        untaxedIncome: args.savingsIncome ?? 0,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: savingsAllowance,
        maxExtraAllowance: maxSavingsAllowance,
        extraAllowanceName: "Savings allowance",
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.taxRates ?? []
    })

    taxedIncome += args.savingsIncome ?? 0;
    incomeTaxDue += savingsIncomeTax.taxDue;

    // Add tax from total dividends income
    const dividendTax = _calculateTax({
        untaxedIncome: totalDividends,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: args.dividendAllowance ?? 0,
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.dividendRates ?? []
    });

    taxedIncome += totalDividends;
    dividendTaxDue += dividendTax.taxDue;

    // Calculate capital gains tax
    const cgTaxProperty = _calculateTax({
        untaxedIncome: args.capitalGainsRedidentialProperty ?? 0,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: args.capitalGainsAllowance ?? 0,
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.capitalGainsRedidentialPropertyRates ?? []
    })

    taxedIncome += args.capitalGainsRedidentialProperty ?? 0;
    capitalGainsTaxDue += cgTaxProperty.taxDue;

    const cgTaxOther = _calculateTax({
        untaxedIncome: args.capitalGainsOther ?? 0,
        taxedIncome: taxedIncome,
        maxPersonalAllowance: maxPersonalAllowance,
        personalAllowance: personalAllowance,
        extraAllowance: Math.max((args.capitalGainsAllowance ?? 0) - (args.capitalGainsRedidentialProperty ?? 0), 0),
        basicRateIncrease: args.giftAidContributions ?? 0,
        taxRates: args.capitalGainsOtherRates ?? []
    })

    taxedIncome += args.capitalGainsOther ?? 0;
    capitalGainsTaxDue += cgTaxOther.taxDue;

    const capitalGainsTotal = (args.capitalGainsRedidentialProperty ?? 0) + (args.capitalGainsOther ?? 0);

    // Calculate the total tax and the effective tax rate    
    let totalTax = 0;
    let totalNI = 0;
    let totalCost = 0;

    if (!options?.excludeIncomeTax) {
        totalTax += incomeTaxDue;
        totalCost += totalSalary + (args.savingsIncome ?? 0) + (args.propertyIncome ?? 0);
    }
    if (!options?.excludeDividendTax) {
        totalTax += dividendTaxDue;
        totalCost += totalDividends;
    }
    if (!options?.excludeCapitalGainsTax) {
        totalTax += capitalGainsTaxDue;
        totalCost += capitalGainsTotal;
    }
    if (!excludeEmployerNI) {
        totalTax += employerNi.taxDue;
        totalNI += employerNi.taxDue;
        totalCost += employerNi.taxDue;
    }
    if (!excludeClass1NI) {
        totalTax += class1Ni.taxDue;
        totalNI += class1Ni.taxDue;
    }
    if (!excludeClass2NI) {
        totalTax += class2Ni.taxDue;
        totalNI += class2Ni.taxDue;
    }
    if (!excludeClass4NI) {
        totalTax += class4Ni.taxDue;
        totalNI += class4Ni.taxDue;
    }
    if (!excludeCorporationTax) {
        totalTax += corporationTax.taxDue;
        totalCost += corporationTax.taxDue;
    }

    totalTax = Math.max(totalTax - (args.foreignTaxesPaid ?? 0), 0);
    const effectiveTaxRate = (totalCost) !== 0 ? (totalTax / totalCost) : 0;
    const afterTaxReceipt = totalCost - totalTax;

    return {
        ...options,
        tradingAs: tradingAs,
        salary: salary,
        grossDividend: grossDividend,
        netDividend: netDividend,
        totalSalary: totalSalary,
        totalDividends: totalDividends,
        propertyIncome: args.propertyIncome ?? 0,
        savingsIncome: args.savingsIncome ?? 0,
        capitalGainsTotal: capitalGainsTotal,
        personalAllowance: personalAllowance,
        totalIncome: taxedIncome,
        taxedIncome: Math.max(taxedIncome - personalAllowance, 0),
        foreignTaxesPaid: args.foreignTaxesPaid ?? 0,
        incomeTaxDue: incomeTaxDue,
        dividendTaxDue: dividendTaxDue,
        salaryTaxBands: salaryTax.taxBands,
        propertyTaxBands: propertyIncomeTax.taxBands,
        savingsTaxBands: savingsIncomeTax.taxBands,
        dividendTaxBands: dividendTax.taxBands,
        capitalGainsTaxResidentialProperty: cgTaxProperty.taxDue,
        capitalGainsTaxOther: cgTaxOther.taxDue,
        capitalGainsTaxDue: capitalGainsTaxDue,
        capitalGainsTaxResidentialPropertyBands: cgTaxProperty.taxBands,
        capitalGainsTaxOtherBands: cgTaxOther.taxBands,
        employerNI: employerNi.taxDue,
        class1NI: class1Ni.taxDue,
        class2NI: class1Ni.taxDue,
        class4NI: class4Ni.taxDue,
        totalNI: totalNI,
        exmployerNIBands: employerNi.taxBands,
        class1NIBands: class1Ni.taxBands,
        class2NIBands: class2Ni.taxBands,
        class4NIBands: class4Ni.taxBands,
        corporationTaxDue: corporationTax.taxDue,
        corporationTaxBands: corporationTax.taxBands,
        corporationTaxRate: corporationTaxRate,
        corporationTaxSalarySaving: corporationTaxSalarySaving,
        totalCompanyTax: totalCompanyTax,
        totalCompanyCost: totalCompanyCost,
        totalTax: totalTax,
        afterTaxReceipt: afterTaxReceipt,
        effectiveTaxRate: effectiveTaxRate,
        excludeCorporationTax: excludeCorporationTax,
        excludeEmployerNI: excludeEmployerNI,
        excludeClass1NI: excludeClass1NI,
        excludeClass2NI: excludeClass2NI,
        excludeClass4NI: excludeClass4NI
    }
}
