import React from 'react';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';
import Typography from "@mui/material/Typography";
import { Divider, InputAdornment, TextField } from '@mui/material';
import IncomeInputs from '../interfaces/IncomeInputs';
import TaxInputs from '../interfaces/TaxInputs';
import getInputEventValue from '../functions/getInputEventValue';
import calculateOptimalTax from '../functions/calculateOptimalTax';
import calculateTax, { CalculateTaxOptions } from '../functions/calculateTax';
import TaxLineChart from './TaxLineChart';

const Grid = Grid2;

interface TaxOptimizerProps {
    open: boolean;
    taxInputs: TaxInputs;
    taxOptions?: CalculateTaxOptions;
    incomeInputs: IncomeInputs;
}

const TaxOptimizer: React.FC<TaxOptimizerProps> = (props) => {
    const {open, taxInputs, incomeInputs, taxOptions} = props;
    const [profit, setProfit] = React.useState<number>();

    const optimimalTaxComputation = React.useMemo(() => {
        if (open === false) {
            return {totalIncome: 0, totalTax: 0, optimialDividend: 0, optimialSalary: 0, effectiveTaxRate: 0, afterTaxReceipt: 0}
        }

        return calculateOptimalTax({
                ...incomeInputs,
                ...taxInputs,
                profit: profit
            }, {
                ...taxOptions,
                tradingAs: "LimitedCompany"
            });
    }, [taxInputs, incomeInputs, profit, taxOptions, open]);

    const allSalaryTaxComputation = React.useMemo(() => {
        if (open === false) {
            return {totalIncome: 0, totalTax: 0, effectiveTaxRate: 0, afterTaxReceipt: 0}
        }

        return calculateTax({
                ...incomeInputs,
                ...taxInputs,
                profit: profit,
                salary: profit
            }, {
                ...taxOptions,
                tradingAs: "LimitedCompany"
            });
    }, [taxInputs, incomeInputs, profit, taxOptions, open]);

    const allDividendsTaxComputation = React.useMemo(() => {
        if (open === false) {
            return {totalIncome: 0, totalTax: 0, effectiveTaxRate: 0, afterTaxReceipt: 0}
        }

        return calculateTax({
                ...incomeInputs,
                ...taxInputs,
                profit: profit,
                salary: 0
            }, {
                ...taxOptions,
                tradingAs: "LimitedCompany"
            });
    }, [taxInputs, incomeInputs, profit, taxOptions, open]);

    const soleTraderTaxComputation = React.useMemo(() => {
        if (open === false) {
            return {totalIncome: 0, totalTax: 0, effectiveTaxRate: 0, afterTaxReceipt: 0}
        }

        return calculateTax({
            ...incomeInputs,
            ...taxInputs,
            profit: profit,
            salary: 0
        }, {
            ...taxOptions,
            tradingAs: "SoleTrader"
        });
    }, [taxInputs, incomeInputs, profit, taxOptions, open]);

    const salaryTaxSaving = allSalaryTaxComputation.totalTax - optimimalTaxComputation.totalTax;
    const dividendTaxSaving = allDividendsTaxComputation.totalTax - optimimalTaxComputation.totalTax;
    const soleTraderTaxSaving = optimimalTaxComputation.totalTax - soleTraderTaxComputation.totalTax;

    const formatSaving = (saving: number) => {
        return saving < 0 ? `(£ ${(-saving).toFixed(2)})` : `£ ${saving.toFixed(2)}`;
    }

    return <>
        <Grid container spacing={4} sx={{width: '100%', my: 2}}>
            <Grid xs={12}>
                <TextField
                    label="Profit"
                    helperText="Profit generated to be paid out as income."
                    type={"number"}
                    value={profit}
                    onChange={(event) => setProfit(getInputEventValue(event))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{width: '100%', my: 2}}>
            <Divider sx={{width: '100%'}} />
            <Grid xs={12}>
                <Typography variant={'h6'}>Trading as a Limited Company</Typography>
            </Grid>
            <Grid xs={12}>
                <Typography variant={'subtitle1'} sx={{fontWeight: 'bold'}}>Optimal salary and dividend split</Typography>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Optimal salary</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{optimimalTaxComputation.optimialSalary.toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Optimal net dividend</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{optimimalTaxComputation.optimialDividend.toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(optimimalTaxComputation.totalIncome).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} sx={{mt: 2}}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Total tax</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(optimimalTaxComputation.totalTax).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>After-tax receipt</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(optimimalTaxComputation.afterTaxReceipt).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Effective tax rate</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>{(100 * optimimalTaxComputation.effectiveTaxRate).toFixed(2)} %</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income if taken as 100% salary</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>
                        £&nbsp;{(allSalaryTaxComputation.totalIncome).toFixed(2)}
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Tax if taken as 100% salary</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>
                        £&nbsp;{(allSalaryTaxComputation.totalTax).toFixed(2)} ({(100 * allSalaryTaxComputation.effectiveTaxRate).toFixed(2)}%)
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Tax saving vs. 100% salary</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>{formatSaving(salaryTaxSaving)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income if taken as 100% dividend</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>
                        £&nbsp;{(allDividendsTaxComputation.totalIncome).toFixed(2)}
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Tax if taken as 100% dividend{(() => {
                            if (allDividendsTaxComputation.effectiveTaxRate < optimimalTaxComputation.effectiveTaxRate) {
                                return <span> <Typography variant='caption'>[1]</Typography></span>;
                            }
                        })()}
                    </Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>
                        £&nbsp;{(allDividendsTaxComputation.totalTax).toFixed(2)} ({(100 * allDividendsTaxComputation.effectiveTaxRate).toFixed(2)}%)
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Tax saving vs. 100% dividend</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>{formatSaving(dividendTaxSaving)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                {(() => {
                    if (allDividendsTaxComputation.effectiveTaxRate < optimimalTaxComputation.effectiveTaxRate) {
                        return <div><Typography variant='caption'>[1] Optimization is calculated for total tax, not the effective tax rate. Corporation tax increases when paying dividends instead of salary, increasing the total cost to the company.</Typography></div>
                    }
                })()}
            </Grid>
            <Divider sx={{width: '100%'}} />
            <Grid xs={12}>
                <Typography variant={'h6'}>Trading as a Sole Trader</Typography>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(soleTraderTaxComputation.totalIncome).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total Tax</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>
                        £&nbsp;{(soleTraderTaxComputation.totalTax).toFixed(2)} ({(100 * soleTraderTaxComputation.effectiveTaxRate).toFixed(2)}%)
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>After-tax receipt</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(soleTraderTaxComputation.afterTaxReceipt).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'subtitle1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Tax saving vs. trading as Limited Company</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>{formatSaving(soleTraderTaxSaving)}</Typography>
                </Stack>
            </Grid>
            <Divider sx={{width: '100%'}} />
        </Grid>
        <Grid container spacing={4} sx={{width: '100%', my: 2}}>
            <Grid xs={12}>
                <Typography variant={'h6'} align='center'>Salary vs Dividends, Trading as a Limited Company</Typography>
            </Grid>
            <Grid xs={12}>
                <TaxLineChart
                    open={open}
                    taxInputs={taxInputs}
                    taxOptions={taxOptions}
                    incomeInputs={incomeInputs}
                    profit={profit ?? 0}
                />
            </Grid>
        </Grid>
    </>
}

export default TaxOptimizer;
