import './App.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IncomeInputsForm from './components/IncomeInputsForm';
import Grid2 from '@mui/material/Unstable_Grid2';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {AccordionDetailsProps} from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import TaxInputsForm from './components/TaxInputsForm';
import TaxInputs from './interfaces/TaxInputs';
import TaxCalculator from './components/TaxCalculator';
import IncomeInputs from './interfaces/IncomeInputs';
import TaxOptimizer from './components/TaxOptimizer';
import CookieConsent from 'react-cookie-consent';
import { CalculateTaxOptions, TraderType } from './functions/calculateTax';
import produce from 'immer';
import { InputLabel, MenuItem, Select } from '@mui/material';
import { Email } from "react-obfuscate-email";
import exp from 'constants';

const Grid = Grid2;


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  }
}));


const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  }
}));

const AccordionDetails = styled((props: AccordionDetailsProps) => (
  <MuiAccordionDetails {...props} />
  ))(({ theme }) => ({
}));


function App() {
  const [taxInputs, setTaxInputs] = React.useState<TaxInputs>({});
  const [optimizerExpanded, setOptimizerExpanded] = React.useState(false);
  const [incomeInputs, setIncomeInputs] = React.useState<IncomeInputs>({});
  const [taxOptimizerOptions, setTaxOptimizerOptions] = React.useState<CalculateTaxOptions>({});
  const [taxCalculatorOptions, setTaxCalculatorOptions] = React.useState<CalculateTaxOptions>(() => ({
    tradingAs: "LimitedCompany"
  }));

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1">
          UK Tax Optimizer
        </Typography>
        <Typography variant="subtitle1">
          A tax calculator for UK solopreneurs
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Typography variant="body1">
          <p>
            If you are a solopreneur and run a UK limited company, or operate as a sole trader,
            this page will help you understand your income tax liabilities.
          </p>
          <p>
            If you trade through a limited company you will pay yourself using a combination of salary and dividends.
            This page may be helpful to you if you are trying to decide how much income to take from your company, and how to
            split that between salary and dividends.
          </p>
          <p>If you are deciding whether opening a Limited Company or trading as a Sole Trader is right for your situation,
            you can calculate how much tax you would owe under both scenarios using this tool.
          </p>
          <p>
            Other online tax calculators exist that will compute how much tax you will owe for
            a given salary and dividend payment, but none (that I could find) gave enough
            details or would take into account corporation tax and other sources of income
            (e.g. income from property).
          </p>
          <p>
            Please note that <strong>I am not a tax advisor</strong> and using this calculator
            is at your own risk. No warantee is made regarding the accuracy of the calculations
            and data on this page. No liability is accepted for any losses due to incorrect information,
            calculations, or anything else! This is not a tool for evading tax and it is your
            responsibility to ensure that you are in compliance with all and any tax laws.
            If you are unsure about anything I recommended that you seek independent tax advice.
          </p>
          <p>If you <strong>are a tax advisor</strong> and would like to use this page, report a problem,
          request a white labelled version with your own branding, or advertise here, please feel free
          to contact me at <Email email="tony@uktaxopimizer.com" />.
          </p>
          <p>
            If you find this helpful, please consider supporting me by buying me a coffee.
            <Box sx={{ my: 1 }}>
              <a href="https://www.buymeacoffee.com/tonyroberts" target="_blank">
                <Box component="img"
                    src="https://cdn.buymeacoffee.com/buttons/default-orange.png" alt="Buy Me A Coffee"
                    sx={{height: "41px", width: "174px", borderRadius: "5px"}}
                />
              </a>
            </Box>
          </p>
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
          <Accordion sx={{width: '100%'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" component="h2">Tax Assumptions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ my: 4 }}>
                <Typography variant="body1">
                  <p>
                    Pre-filled tax details are available for some years which you can choose
                    from below.
                  </p>
                  <p>
                    These are based on my own interpretation of the UK tax rates and may be
                    subject to errors, or may not reflect your specific circumstances.
                    You should check these and make changes where appropriate.
                  </p>
                  <ul>
                    <li>
                      The savings allowance may be different depending on if you
                      are a lower or higher rate tax payer. The defaults assumed
                      the higher rate tax value. 
                    </li>
                    <li>
                      The property allowance will be different if you wish to claim property
                      income tax allowance instead of deducting expenses.
                    </li>
                    <li>
                      The employee NI thresholds assume you are a director and may need to be adjusted
                      depending on your exact circumstances.
                    </li>
                  </ul>
                </Typography>
              </Box>
              <TaxInputsForm
                taxInputs={taxInputs}
                setTaxInputs={setTaxInputs}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{width: '100%'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" component="h2">Other Income Assumptions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ my: 4 }}>
                <Typography variant="body1">
                  <p>
                    If you have sources of income other than what you will take from your
                    limited company you can enter them here. These will be included in
                    the tax calculations below.
                  </p>
                </Typography>
              </Box>
              <IncomeInputsForm
                incomeInputs={incomeInputs}
                setIncomeInputs={setIncomeInputs}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{width: '100%'}} expanded={optimizerExpanded} onChange={(event: any, expanded: boolean) => setOptimizerExpanded(expanded)} >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" component="h2">Tax Optimizer</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ my: 4 }}>
                <Typography variant="body1">
                  <p>
                    Enter the amount you will pay yourself below.
                    This is in addition to any income entered above.
                  </p>
                  <p>
                    The optimal salary and dividend split will be calculated and shown below.
                    The total tax calculated includes:
                    <List dense={true}>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                  checked={!taxOptimizerOptions.excludeIncomeTax}
                                  onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeIncomeTax = !e.target.checked}))}
                        />
                        Income tax
                      </ListItem>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                    checked={!taxOptimizerOptions.excludeDividendTax}
                                    onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeDividendTax = !e.target.checked}))}
                        />
                        Dividend tax
                      </ListItem>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                    checked={!taxOptimizerOptions.excludeCapitalGainsTax}
                                    onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeCapitalGainsTax = !e.target.checked}))}
                        />
                        Capital gains tax
                      </ListItem>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                      checked={!taxOptimizerOptions.excludeEmployerNI}
                                      onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeEmployerNI = !e.target.checked}))}
                        />
                          Employer National Insurance Contributions
                        </ListItem>
                        <ListItem sx={{pt: 0, pb: 0}}>
                          <Checkbox sx={{padding: 1}}
                                      checked={!taxOptimizerOptions.excludeClass1NI}
                                      onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeClass1NI = !e.target.checked}))}
                        />
                          Class 1 National Insurance Contributions
                        </ListItem>
                        <ListItem sx={{pt: 0, pb: 0}}>
                          <Checkbox sx={{padding: 1}}
                                      checked={!taxOptimizerOptions.excludeClass2NI}
                                      onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeClass2NI = !e.target.checked}))}
                          />
                          Class 2 National Insurance Contributions
                        </ListItem>
                        <ListItem sx={{pt: 0, pb: 0}}>
                          <Checkbox sx={{padding: 1}}
                                      checked={!taxOptimizerOptions.excludeClass4NI}
                                      onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeClass4NI = !e.target.checked}))}
                          />
                          Class 4 National Insurance Contributions
                        </ListItem>
                        <ListItem sx={{pt: 0, pb: 0}}>
                          <Checkbox sx={{padding: 1}}
                                          checked={!taxOptimizerOptions.excludeCorporationTax}
                                          onChange={(e) => setTaxOptimizerOptions(produce((draft) => {draft.excludeCorporationTax = !e.target.checked}))}
                          />
                          Corporation tax paid on dividends
                        </ListItem>
                    </List>
                  </p>
                  <p>Dividends can only be paid from retained profits and it is assumed that
                    you will have sufficient retained profits to make the dividend payment, and
                    that tax on those profits is paid at the current corporation tax rate.
                    This assumes that if they weren't paid as dividends they would be paid as
                    salary, and in that case would be offset against corporation tax.
                  </p>
                  <p>
                    This may not be a complete or accurate tax calculation. Depending on your circumstances
                    there may be details that are not considered in this calculation. For an accurate
                    calculation you should review the latest guidance from HMRC or seek independent
                    tax advice.
                  </p>
                </Typography>
              </Box>
              <TaxOptimizer
                open={optimizerExpanded}
                taxInputs={taxInputs}
                taxOptions={taxOptimizerOptions}
                incomeInputs={incomeInputs}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{width: '100%'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" component="h2">Tax Calculator</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Box sx={{ my: 4 }}>
                <Typography variant="body1">
                  <p>
                    Enter the amount you will pay yourself below as salary and/or dividends.
                  </p>
                  <p>
                    The tax calculation uses these values and the other income entered above
                    and calculates the total tax paid, including:
                    <List dense={true}>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                  checked={!taxCalculatorOptions.excludeIncomeTax}
                                  onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeIncomeTax = !e.target.checked}))}
                        />
                        Income tax
                      </ListItem>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                    checked={!taxCalculatorOptions.excludeDividendTax}
                                    onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeDividendTax = !e.target.checked}))}
                        />
                        Dividend tax
                      </ListItem>
                      <ListItem sx={{pt: 0, pb: 0}}>
                        <Checkbox sx={{padding: 1}}
                                    checked={!taxCalculatorOptions.excludeCapitalGainsTax}
                                    onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeCapitalGainsTax = !e.target.checked}))}
                        />
                        Capital gains tax
                      </ListItem>


                      {(() => {
                          if (taxCalculatorOptions.tradingAs === "SoleTrader") {
                            return <>
                              <ListItem sx={{pt: 0, pb: 0}}>
                                <Checkbox sx={{padding: 1}}
                                            checked={!taxCalculatorOptions.excludeClass2NI}
                                            onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeClass2NI = !e.target.checked}))}
                                />
                                Class 2 National Insurance Contributions
                              </ListItem>
                              <ListItem sx={{pt: 0, pb: 0}}>
                                <Checkbox sx={{padding: 1}}
                                            checked={!taxCalculatorOptions.excludeClass4NI}
                                            onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeClass4NI = !e.target.checked}))}
                                />
                                Class 4 National Insurance Contributions
                              </ListItem>
                            </>
                          }
                          else {
                            return (
                              <>
                                <ListItem sx={{pt: 0, pb: 0}}>
                                  <Checkbox sx={{padding: 1}}
                                                checked={!taxCalculatorOptions.excludeEmployerNI}
                                                onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeEmployerNI = !e.target.checked}))}
                                  />
                                    Employer National Insurance Contributions
                                  </ListItem>
                                  <ListItem sx={{pt: 0, pb: 0}}>
                                    <Checkbox sx={{padding: 1}}
                                                checked={!taxCalculatorOptions.excludeClass1NI}
                                                onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeClass1NI = !e.target.checked}))}
                                  />
                                    Class 1 National Insurance Contributions
                                  </ListItem>
                                  <ListItem sx={{pt: 0, pb: 0}}>
                                    <Checkbox sx={{padding: 1}}
                                                    checked={!taxCalculatorOptions.excludeCorporationTax}
                                                    onChange={(e) => setTaxCalculatorOptions(produce((draft) => {draft.excludeCorporationTax = !e.target.checked}))}
                                    />
                                    Corporation tax paid on dividends
                                  </ListItem>
                              </>
                            )
                          }
                      })()}
                    </List>
                  </p>
                  <p>
                    This may not be a complete or accurate tax calculation. Depending on your circumstances
                    there may be details that are not considered in this calculation. For an accurate
                    calculation you should review the latest guidance from HMRC or seek independent
                    tax advice.
                  </p>
                </Typography>
              </Box>
              <Box sx={{ my: 4 }}>
                <InputLabel id="trading-as-select-label">I am trading as a</InputLabel>
                <Select
                    labelId="trading-as-select-label"
                    id="trading-as-select"
                    value={taxCalculatorOptions.tradingAs}
                    onChange={(event) => setTaxCalculatorOptions(produce((draft) => { draft.tradingAs = event.target.value as TraderType  }))}
                >
                    <MenuItem value={"LimitedCompany"}>Limited Company</MenuItem>
                    <MenuItem value={"SoleTrader"}>Sole Trader</MenuItem>
                </Select>
              </Box>
              <TaxCalculator
                taxInputs={taxInputs}
                taxOptions={taxCalculatorOptions}
                incomeInputs={incomeInputs}
              />           
            </AccordionDetails>
          </Accordion>
      </Box>
      <CookieConsent
          buttonText={"I'm fine with that"}
          buttonStyle={{ color: "#4e503b", fontSize: "14px", borderRadius: "5px"}}
      >
        This website uses cookies
      </CookieConsent>
    </Container>
  );
}

export default App;
