import * as React from 'react';
import {DataGrid, GridColDef, GridRowSelectionModel, GridRowModel, GridCellParams} from '@mui/x-data-grid';
import {Box,  IconButton, Tooltip} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import produce from 'immer';
import TaxAllowance from '../interfaces/TaxAllowance';

interface TaxAllowancessGridProps {
    allowances: TaxAllowance[]
    setAllowances: React.Dispatch<React.SetStateAction<TaxAllowance[]>>
}

const columns: GridColDef[] = [
    { field: 'lower', headerName: 'From', type: 'number', width: 200, valueFormatter: ({value}) => `£ ${(value ?? 0).toFixed(2)}`},
    { field: 'upper', headerName: 'Up to', type: 'number', width: 200, valueFormatter: ({value}) => `£ ${value ? value.toFixed(2) : ' - '}`, editable: true},
    { field: 'allowance', headerName: 'Allowance', type: 'number', width: 100, valueFormatter: ({value}) => `£ ${(value ?? 0).toFixed(2)}`, editable: true},
    { field: 'taperFactor', headerName: 'Taper Amount', type: 'number', width: 100, valueFormatter: ({value}) => `£ ${(value ?? 0).toFixed(2)}`, editable: true},
];

interface Row {
    id: number;
    idx?: number;
    lower?: number;
    upper?: number;
    allowance?: number;
    taperFactor?: number;
}

const TaxAllowancesGrid: React.FC<TaxAllowancessGridProps> = (props) => {
    const {allowances, setAllowances} = props;
    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])

    const handleRowUpdate = (newRow: GridRowModel<Row>, oldRow: GridRowModel<Row>) => {
        if (oldRow.idx !== undefined) {
            setAllowances(produce((draft) => {
                if (oldRow.idx !== undefined) {
                    draft[oldRow.idx].threshold = newRow.upper ?? 0;
                    draft[oldRow.idx].allowance = newRow.allowance ?? 0;
                    draft[oldRow.idx].taperFactor = newRow.taperFactor;
                }
            }))
        }
        else if (oldRow.id === 0) {
            setAllowances(produce((draft) => {
                // Add a new amount to the beginning of the list
                return [{
                    threshold: newRow.upper,
                    allowance: newRow.allowance ?? 0,
                    taperFactor: newRow.taperFactor
                }, ...draft];
            }));
        }
        else {
            setAllowances(produce((draft) => {
                // Add a new amount to the end of the list
                return [...draft, {
                    threshold: newRow.upper,
                    allowance: newRow.allowance ?? 0,
                    taperFactor: newRow.taperFactor
                }];
            }));
        }

        return newRow;
    }

    const handleDeleteRate = () => {
        setAllowances(produce((draft) => {
            // Get the selected mappings without changing the indexing of the array
            const selected = new Map(rowSelectionModel.map((rowId): [Row | undefined, any] => [rows.find((r) => r.id === rowId), rowId])
                .filter(([row, rowId]) => row?.idx !== undefined)
                .map(([row, rowId]) => [row?.idx, rowId])
            );

            // Return the draft exluding any deleted ones
            return draft.filter((m, idx) => !selected.has(idx))
        }));
    }

    const isCellEditable = (params: GridCellParams<Row>) => {
        if (params.colDef.field === 'lower') {
            return false;
        }
        
        return true;
    }

    const rows: Row[] = React.useMemo(() => {
        const rows: Row[] = [];
        let n = 0;

        let prev = 0;
        allowances.forEach((allowance, idx) => {
            rows.push({
                id: n++,
                lower: prev,
                upper: allowance.threshold,
                allowance: allowance.allowance ?? 0,
                taperFactor: allowance.taperFactor,
                idx: idx
            })
            prev = Math.max(allowance.threshold ?? prev, prev);
        });

        if (rows.length === 0) {
            rows.push({
                id: n++,
                lower: prev,
                upper: undefined,
                allowance: 0,
                taperFactor: 0
            })
        }

        return rows;
    }, [allowances])

    return <Box sx={{width: '100%' }}>
        <DataGrid
            autoHeight={true}
            rows={rows}
            columns={columns}
            isCellEditable={isCellEditable}
            processRowUpdate={handleRowUpdate}
            onProcessRowUpdateError={(error) => console.error(error)}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={(newSelectionModel) => {
                setRowSelectionModel(newSelectionModel);
            }}
        />
        <Box sx={{ width: 1, textAlign: 'right' }}>
            <Tooltip title={"Delete Allowance"}>
                <span>
                    <IconButton
                        aria-label={"Delete Allowance"}
                        sx={{margin: 1, marginLeft: 2}}
                        onClick={handleDeleteRate}
                        disabled={rowSelectionModel.length === 0}
                    >
                        <DeleteIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </Box>
    </Box>
}


export default TaxAllowancesGrid;
