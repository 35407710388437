import React from "react"
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import calculateTax, { CalculateTaxOptions, TaxComputationResult } from '../functions/calculateTax';
import IncomeInputs from "../interfaces/IncomeInputs";
import TaxInputs from "../interfaces/TaxInputs";
import { CurveType } from "recharts/types/shape/Curve";


const data = [
    {
      "name": "Page A",
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "name": "Page B",
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "name": "Page C",
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "name": "Page D",
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "name": "Page E",
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "name": "Page F",
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "name": "Page G",
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]

  interface TaxLineChartProps {
    open: boolean;
    taxInputs: TaxInputs;
    taxOptions?: CalculateTaxOptions;
    incomeInputs: IncomeInputs;
    profit: number;
}

const TaxLineChart: React.FC<TaxLineChartProps> = (props) => {
    const {open, taxInputs, taxOptions, incomeInputs, profit} = props;

    const data = React.useMemo(() => {
        if (!open) {
            return [];
        }

        const numPoints = 100;

        return Array.from({length: numPoints+1}, (_, i) => {
            const x = i / numPoints;
            const salary = Math.floor(profit * x);
            return {
                ...calculateTax({
                    ...incomeInputs,
                    ...taxInputs,
                    profit: profit,
                    salary: salary
                }, taxOptions)
            }
        });
    }, [taxInputs, taxOptions, incomeInputs, profit, open])

    const options = React.useMemo(() => {
        let options = data.reduce((prev, row) => {
            return {
                excludeIncomeTax: (prev.excludeIncomeTax ?? true) && (row.excludeIncomeTax ?? false),
                excludeDividendTax: (prev.excludeDividendTax ?? true) && (row.excludeDividendTax ?? false),
                excludeEmployerNI: (prev.excludeEmployerNI ?? true) && (row.excludeEmployerNI ?? false),
                excludeClass1NI: (prev.excludeClass1NI ?? true) && (row.excludeClass1NI ?? false),
                excludeClass2NI: (prev.excludeClass2NI ?? true) && (row.excludeClass2NI ?? false),
                excludeClass4NI: (prev.excludeClass4NI ?? true) && (row.excludeClass4NI ?? false),
                excludeCorporationTax: (prev.excludeCorporationTax ?? true) && (row.excludeCorporationTax ?? false)
            };
        }, {} as CalculateTaxOptions);

        if (taxOptions?.tradingAs !== "SoleTrader") {
            options.excludeClass2NI = true;
            options.excludeClass4NI = true;
        } else {
            options.excludeCorporationTax = true;
        }

        return options;
    }, [data])

    const lines = React.useMemo(() => {
        const lineProps = {
            type: "monotone" as CurveType,
            yAxisId: "left",
            dot: false
        }

        let lines = [
            <Line
                {...lineProps}
                name="Total Tax"
                stroke="#e76d63"
                dataKey="totalTax"
            />,
            <Line
                {...lineProps}
                name="Net Dividend"
                stroke="#bfa9a8"
                dataKey="netDividend"
            />
        ]

        if (!options.excludeIncomeTax) {
            lines.push(<Line {...lineProps} stroke="#91d554" dataKey="incomeTaxDue" name="Income Tax"/>);
        }

        if (!options.excludeDividendTax) {
            lines.push(<Line {...lineProps} stroke="#d071d4" dataKey="dividendTaxDue" name="Dividend Tax"/>);
        }

        if (!options.excludeEmployerNI) {
            lines.push(<Line {...lineProps} dataKey="employerNI" stroke="#7bc9a4" name="Employee NIC"/>);
        }

        if (!options.excludeClass1NI) {
            lines.push(<Line {...lineProps} stroke="#86a4db" dataKey="class1NI" name="Class 1 NIC"/>);
        }

        if (!options.excludeClass2NI) {
            lines.push(<Line {...lineProps} stroke="#86a4db" dataKey="class2NI" name="Class 2 NIC"/>);
        }

        if (!options.excludeClass4NI) {
            lines.push(<Line {...lineProps} stroke="#86a4db" dataKey="class4NI" name="Class 4 NIC"/>);
        }

        if (!options.excludeCorporationTax) {
            lines.push(<Line type="monotone" yAxisId="left" dataKey="corporationTaxDue" dot={false} stroke="#ccac52" name="Corporation Tax"/>);
        }

        return lines;
    }, [taxOptions])

    return <ResponsiveContainer width={'100%'} height={750}>
        <LineChart data={data} margin={{ top: 30, right: 30, left: 30, bottom: 30 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis xAxisId={0}
                dataKey={(t) => +t.salary.toFixed(0)}
                type={'number'}
                domain={['dataMin', 'dataMax']}
                tickCount={12}
                orientation={'bottom'}
                name={"Salary"}
                label={{value: "Salary", position:'insideBottomMiddle', offset: 10, dy: 5}}
                unit={'£'}
                height={55}
            />
            <YAxis
                yAxisId={"left"}
                type={"number"}
                unit={'£'}
            />
            <Tooltip />
            <Legend />
            {lines}
        </LineChart>
    </ResponsiveContainer>
}


export default TaxLineChart;