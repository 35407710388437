import React, {Dispatch, SetStateAction} from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import TaxRatesGrid from "./TaxRatesGrid";
import TaxRate from "../interfaces/TaxRate";
import TaxInputs from "../interfaces/TaxInputs";
import produce from 'immer';
import Typography from "@mui/material/Typography";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid2 from '@mui/material/Unstable_Grid2';
import Select from '@mui/material/Select';
import getTaxInputs from "../functions/getTaxInputs";
import getInputEventValue from "../functions/getInputEventValue";
import TaxAllowance from "../interfaces/TaxAllowance";
import TaxAllowancesGrid from "./TaxAllowancesGrid";

const Grid = Grid2;


interface TaxInputsFormProps {
    taxInputs: TaxInputs;
    setTaxInputs: Dispatch<SetStateAction<TaxInputs>>;
}


const TaxInputsForm: React.FC<TaxInputsFormProps> = (props) => {
    const {taxInputs, setTaxInputs} = props;
    const [taxYear, setTaxYear] = React.useState<number>()

    const setCorporationTaxRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.corporationTaxRates = value(draft.corporationTaxRates ?? []);
            } else {
                draft.corporationTaxRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setTaxRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.taxRates = value(draft.taxRates ?? []);
            } else {
                draft.taxRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setSavingsAllowances = React.useCallback((value: TaxAllowance[] | ((prev: TaxAllowance[]) => TaxAllowance[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.savingsAllowances = value(draft.savingsAllowances ?? []);
            } else {
                draft.savingsAllowances = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setDividendRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.dividendRates = value(draft.dividendRates ?? []);
            } else {
                draft.dividendRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setNIEmployerRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.niEmployerRates = value(draft.niEmployerRates ?? []);
            } else {
                draft.niEmployerRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setNIClass1Rates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.niClass1Rates = value(draft.niClass1Rates ?? []);
            } else {
                draft.niClass1Rates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setNIClass2Rates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.niClass2Rates = value(draft.niClass2Rates ?? []);
            } else {
                draft.niClass2Rates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setNIClass4Rates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.niClass4Rates = value(draft.niClass4Rates ?? []);
            } else {
                draft.niClass4Rates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setCGPropertyRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.capitalGainsRedidentialPropertyRates = value(draft.capitalGainsRedidentialPropertyRates ?? []);
            } else {
                draft.capitalGainsRedidentialPropertyRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    const setCGOtherRates = React.useCallback((value: TaxRate[] | ((prev: TaxRate[]) => TaxRate[])) => {
        setTaxInputs(produce((draft) => {
            if (typeof(value) === 'function') {
                draft.capitalGainsOtherRates = value(draft.capitalGainsOtherRates ?? []);
            } else {
                draft.capitalGainsOtherRates = value;
            }
        }))
    }, [taxInputs, setTaxInputs])

    React.useEffect(() => {
        const inputs = getTaxInputs(taxYear ?? 2023);
        if (inputs !== undefined) {
            setTaxInputs(inputs);
        }
    }, [taxYear, setTaxInputs])

    return <Grid container spacing={4} sx={{width: '100%', my: 2}}>
        <Grid xs={12}>
            <InputLabel id="tax-year-select-label">Tax Year</InputLabel>
                <Select
                    labelId="tax-year-select-label"
                    id="tax-year-select"
                    value={taxYear ?? 2023}
                    onChange={(event) => setTaxYear(getInputEventValue(event))}
                >
                    <MenuItem value={2021}>2021-22</MenuItem>
                    <MenuItem value={2022}>2022-23</MenuItem>
                    <MenuItem value={2023}>2023-24</MenuItem>
                </Select>
        </Grid>
        <Grid xs={12}>
            <TextField
                    label="Personal Allowance"
                    helperText="Personal income allowance zero tax band"
                    type={'number'}
                    value={taxInputs.personalAllowance?.allowance}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.personalAllowance = {...draft.personalAllowance, allowance: getInputEventValue(event)}}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid md={6} sx={{width: '100%'}}>
            <TextField
                    label="Personal Allowance Threshold"
                    helperText="Income after which the personal allowance is reduced"
                    type={'number'}
                    value={taxInputs.personalAllowance?.threshold}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.personalAllowance = {...draft.personalAllowance, threshold: getInputEventValue(event)}}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid md={6} sx={{width: '100%'}}>
            <TextField
                    label="Personal Allowance Taper Amount"
                    helperText="Number of pounds the personal allowance is reduced by for every pound over the threshold"
                    type={'number'}
                    value={taxInputs.personalAllowance?.taperFactor}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.personalAllowance = {...draft.personalAllowance, taperFactor: getInputEventValue(event)}}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid xs={12}>
            <TextField
                    label="Dividend Allowance"
                    helperText="Dividend allowance zero tax band"
                    type={'number'}
                    value={taxInputs.dividendAllowance ?? 0}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.dividendAllowance = getInputEventValue(event)}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid xs={12}>
            <TextField
                    label="Property Allowance"
                    helperText="Property allowance zero tax band"
                    type={'number'}
                    value={taxInputs.propertyIncomeAllowance ?? 0}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.propertyIncomeAllowance = getInputEventValue(event)}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid xs={12}>
            <TextField
                    label="Capital Gains Allowance"
                    helperText="Capital Gains allowance zero tax band"
                    type={'number'}
                    value={taxInputs.capitalGainsAllowance ?? 0}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.capitalGainsAllowance = getInputEventValue(event)}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Income Tax Rates
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.taxRates ?? []}
                setRates={setTaxRates}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Dividend Tax Rates
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.dividendRates ?? []}
                setRates={setDividendRates}
            />
        </Grid>
        <Grid xs={12}> 
            <Typography variant="subtitle1" component="h3">
                Employer National Insurance
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.niEmployerRates ?? []}
                setRates={setNIEmployerRates}
                includeFixedAmounts={true}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Class 1 National Insurance
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.niClass1Rates ?? []}
                setRates={setNIClass1Rates}
                includeFixedAmounts={true}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Class 2 National Insurance
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.niClass2Rates ?? []}
                setRates={setNIClass2Rates}
                includeFixedAmounts={true}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Class 4 National Insurance
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.niClass4Rates ?? []}
                setRates={setNIClass4Rates}
                includeFixedAmounts={true}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Savings Allowances
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxAllowancesGrid
                allowances={taxInputs.savingsAllowances ?? []}
                setAllowances={setSavingsAllowances}
            />
        </Grid>
        <Grid xs={12}>
            <Typography variant="subtitle1" component="h3">
                Corporation Tax Rates
            </Typography>
            {/* <TextField
                    label="Corporation Tax"
                    helperText="Corporation tax rate applied to company profits"
                    type={'number'}
                    value={!taxInputs.corporationTaxRate ? '' : (taxInputs.corporationTaxRate * 100)}
                    onChange={(event) => setTaxInputs(produce((draft) => {draft.corporationTaxRate = (getInputEventValue(event) ?? 0) / 100}))}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                    sx={{width: '100%'}}
                /> */}
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.corporationTaxRates ?? []}
                setRates={setCorporationTaxRates}
            />
        </Grid>
        <Grid xs={12}> 
            <Typography variant="subtitle1" component="h3">
                Capital Gains Tax on Residential Property
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.capitalGainsRedidentialPropertyRates ?? []}
                setRates={setCGPropertyRates}
            />
        </Grid>
        <Grid xs={12}> 
            <Typography variant="subtitle1" component="h3">
                Capital Gains Tax on Other Assets
            </Typography>
        </Grid>
        <Grid xs={12}>
            <TaxRatesGrid
                rates={taxInputs.capitalGainsOtherRates ?? []}
                setRates={setCGOtherRates}
            />
        </Grid>    
    </Grid>
}

export default TaxInputsForm;
