import calculateTax, { CalculateTaxInputs, CalculateTaxOptions, TaxComputationResult } from "./calculateTax";

export type CalculateOptimalTaxInputs = Omit<CalculateTaxInputs, "salary" | "dividends"> & {
    grossIncome?: number
}

export type OptimialTaxComputationResult = TaxComputationResult & {
    optimialSalary: number,
    optimialDividend: number
};

export type OptimizeTaxOptions = CalculateTaxOptions & {
}


export default function calculateOptimalTax(args: CalculateOptimalTaxInputs, options?: OptimizeTaxOptions): OptimialTaxComputationResult {

    const profit = args.profit ?? 0;

    const costFunc = (x: number) => {
        return calculateTax({
            ...args,
            salary: profit * x,
        }, options).totalTax;
    }

    // Solve for minimizing the total tax by scanning from 0% to 100% salary, varying the step size
    // as we get closer to the answer. This is repeated from different evenly spaced starting points
    // to try to find the global minima. The constants are chosen so they are not multiples of each other.
    let minCost = Infinity;
    let minX = NaN;
    const tolerance = 1e-6;
    const minStepSize = 1e-6;
    const maxIterations = 10000;
    const numSamples = 15;

    for (let i = 0; i < numSamples; ++i) {
        let iterations = 0;
        let x = i / numSamples;
        let step = 0.012;
        let prevCost = Infinity;
        while (iterations < maxIterations) {
            const cost = costFunc(x);

            if (cost < minCost) {
                // If the new cost is lower than the min so far, take it
                minX = x;
                minCost = cost;
            }
            else if (Math.abs(cost - minCost) < tolerance) {
                // If the cost is within tolerance of the current min, take the larger x (favor more salary)
                minX = Math.max(x, minX);
                if (step < 0) {
                    // Keep increasing x from here since we want the result that favours the most salary
                    step = -step/2;
                }
            }

            // If the cost is increasing, switch direction
            if (cost > prevCost) {
                step = -step/2;
            }

            prevCost = cost;
            x += step;
            iterations += 1;

            // If we've gone out of bounds clamp and reverse direction
            if (x > 1.0) {
                x = 1.0;
                step = -Math.abs(step/2);
            } else if (x < 0.0) {
                x = 0.0
                step = Math.abs(step/2);
            }

            // Finish when the step size is close to zero
            if (Math.abs(step) < minStepSize) {
                break;
            }
        }
    }

    // Round the salary to the nearest whole integer and use that
    const optimialSalary = Math.round(profit * minX);

    const optimalResult = calculateTax({
        ...args,
        salary: optimialSalary
    }, options);

    const optimialDividend = optimalResult.netDividend ?? 0;

    return {
        ...optimalResult,
        optimialSalary,
        optimialDividend
    };
}
