import React, { Dispatch, SetStateAction } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Divider from '@mui/material/Divider';
import TaxInputs from '../interfaces/TaxInputs';
import IncomeInputs from '../interfaces/IncomeInputs';
import calculateTax, { TaxComputationResult, TaxComputationBand, CalculateTaxOptions } from '../functions/calculateTax';
import produce from 'immer';
import getInputEventValue from '../functions/getInputEventValue';

const Grid = Grid2;

interface TaxCalculatorProps {
    taxInputs: TaxInputs;
    taxOptions?: CalculateTaxOptions;
    incomeInputs: IncomeInputs;
}


const TaxCalculatorTable: React.FC<{taxes: [TaxComputationBand[], string][]}> = (props) => {
    const {taxes} = props;

    const notes = Array.from(new Set(taxes.map(([taxBands, taxType]) => {
        return taxBands.filter((band) => !!band.note).map((band) => band.note)
    }).reduce((prev, notes) => prev.concat(notes), [])));

    const getRows = (bands: TaxComputationBand[], taxType: string) => {
        return bands
            .filter((band) => band.income > 0 || !!band.note)
            .map((band) => {
                const noteIdx = !!band.note ? notes.findIndex((note) => note === band.note) : undefined;
                return <TableRow>
                    <TableCell>
                        {`${taxType} £ ${band.taxBand[0].toFixed(2)} - £ ${band.taxBand[1].toFixed(2)}`}
                        { noteIdx !== undefined ? <Typography variant='caption'>{` [${noteIdx+1}]`}</Typography> : undefined }
                    </TableCell>
                    <TableCell align="right">{`£ ${band.income.toFixed(2)}`}</TableCell>
                    <TableCell align="right">{`${(band.rate * 100).toFixed(2)} %`}</TableCell>
                    <TableCell align="right">{`£ ${band.tax.toFixed(2)}`}</TableCell>
                </TableRow>
            })
    }

    const rows = taxes
        .map(([taxBands, taxType]) => getRows(taxBands, taxType))
        .reduce((prev, rows) => prev.concat(rows), []);

    const footer = notes.length > 0
        ? <TableFooter>
            {notes.map((note, idx) => <div><Typography variant='caption'>[{idx+1}] {note}</Typography></div>)}
        </TableFooter>
        : undefined;

    return <TableContainer sx={{width: '100%'}}>
      <Table sx={{ minWidth: 650 }} aria-label="tax computation table">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Percentage</TableCell>
                    <TableCell align="right">Total</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    {rows}
            </TableBody>
            {footer}
        </Table>
    </TableContainer>
}

const IncomeTaxCalculatorTable: React.FC<{taxComputation: TaxComputationResult}> = (props) => {
    const {taxComputation} = props;

    return <TaxCalculatorTable
        taxes={[
            [taxComputation.salaryTaxBands, "Salary"],
            [taxComputation.propertyTaxBands, "Property Income"],
            [taxComputation.savingsTaxBands, "Savings Income"],
            [taxComputation.dividendTaxBands, "Dividends"],
            [taxComputation.capitalGainsTaxResidentialPropertyBands, "Capital Gains (Residential Property)"],
            [taxComputation.capitalGainsTaxOtherBands, "Capital Gains (Other)"],
        ]}
    />
}

const NICalculatorTable: React.FC<{taxComputation: TaxComputationResult}> = (props) => {
    const {taxComputation} = props;

    return <TaxCalculatorTable
        taxes={[
            [taxComputation.exmployerNIBands, "Employer NICs"],
            [taxComputation.class1NIBands, "Class 1 NICs"],
            [taxComputation.class2NIBands, "Class 2 NICs"],
            [taxComputation.class4NIBands, "Class 4 NICs"],
        ]}
    />
}


const CorporationTaxCalculatorTable: React.FC<{taxComputation: TaxComputationResult}> = (props) => {
    const {taxComputation} = props;

    return <TaxCalculatorTable
        taxes={[
            [taxComputation.corporationTaxBands, "Corporation Tax"],
        ]}
    />
}



const TaxCalculator: React.FC<TaxCalculatorProps> = (props) => {
    const {taxInputs, incomeInputs, taxOptions} = props;
    const [profit, setProfit] = React.useState<number>();
    const [salary, setSalary] = React.useState<number>();


    const taxComputation = React.useMemo(() => {
        if (taxOptions?.tradingAs === "SoleTrader") {
            return calculateTax({...incomeInputs, ...taxInputs, profit: profit, salary: 0}, taxOptions);
        }
        else {
            return calculateTax({...incomeInputs, ...taxInputs, profit: profit, salary: salary}, taxOptions);
        }
    }, [taxInputs, incomeInputs, taxOptions, profit, salary]);

    return <>
        <Grid container spacing={4} sx={{width: '100%', my: 2}}>
            {(() => {
                if (taxOptions?.tradingAs === "SoleTrader") {
                    return <>
                        <Grid xs={12}>
                            <TextField
                                label="Profit"
                                helperText="Profits generated as a self employed sole trader"
                                type={"number"}
                                value={profit}
                                onChange={(event) => setProfit(getInputEventValue(event))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </>
                }
                else {
                    return <>
                        <Grid xs={12}>
                            <TextField
                                label="Profit"
                                helperText="Profits generated by your limited company to be paid out as income."
                                type={"number"}
                                value={profit}
                                onChange={(event) => setProfit(getInputEventValue(event))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                label="Gross Salary"
                                helperText="Income taken as salary from your limited company, excluding other income."
                                type={"number"}
                                value={salary}
                                onChange={(event) => setSalary(getInputEventValue(event))}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                }}
                                sx={{width: '100%'}}
                            />
                        </Grid>
                    </>
                }
            })()}

        </Grid>
        <Grid container spacing={2} sx={{width: '100%', my: 2}}>
            <Grid xs={12}>
                <Typography variant={'subtitle1'} sx={{fontWeight: 'bold'}}>Calculation Summary</Typography>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Pay from all employments</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.totalSalary ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Profit from land and properties</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.propertyIncome ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total dividend income</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.totalDividends ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Interest from savings</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.savingsIncome ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.totalIncome - taxComputation.capitalGainsTotal).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Capital Gains</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.capitalGainsTotal ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Personal allowance</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.personalAllowance ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total income on which tax is due</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.taxedIncome ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Divider sx={{width: '100%'}} />
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>UK income tax due</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.incomeTaxDue + taxComputation.dividendTaxDue).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Capital Gains tax due</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.capitalGainsTaxDue).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Foreign taxes witheld/paid</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>(£&nbsp;{(taxComputation.foreignTaxesPaid).toFixed(2)})</Typography>
                </Stack>
            </Grid>
            <Divider sx={{width: '100%'}} />
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Total income tax due</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(taxComputation.incomeTaxDue + taxComputation.dividendTaxDue + taxComputation.capitalGainsTaxDue - taxComputation.foreignTaxesPaid).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            {(() => {
                if (taxComputation.tradingAs === "SoleTrader") {
                    return (
                        <>
                        <Grid xs={12} sx={{mt: 2}}>
                            <Stack sx={{width: '100%'}} direction="row">
                                <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>NI contributions</Typography>
                                <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.class4NI ?? 0).toFixed(2)}</Typography>
                            </Stack>
                        </Grid>
                        <Divider sx={{width: '100%'}} />
                        </>
                    )
                }
                else {
                    return (
                        <>
                            <Grid xs={12} sx={{mt: 2}}>
                            <Stack sx={{width: '100%'}} direction="row">
                                <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Employee NI contributions</Typography>
                                <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.class1NI ?? 0).toFixed(2)}</Typography>
                            </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Employer NI contributions</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.employerNI ?? 0).toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Divider sx={{width: '100%'}} />
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Total NI contributions</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(taxComputation.totalNI ?? 0).toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} sx={{mt: 2}}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Net dividend</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{taxComputation.netDividend.toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Gross dividend</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{taxComputation.grossDividend.toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Corporation tax</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{(taxComputation.corporationTaxDue ?? 0).toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Effective Corporation tax rate</Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>{((taxComputation.corporationTaxRate ?? 0) * 100).toFixed(2)}&nbsp;%</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Corporation tax saving from salary taken <Typography variant='caption'>[1]</Typography></Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>(£&nbsp;{(taxComputation.corporationTaxSalarySaving ?? 0).toFixed(2)})</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto'}}>Total cost to company <Typography variant='caption'>[2]</Typography></Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto'}}>£&nbsp;{taxComputation.totalCompanyCost.toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12}>
                                <Stack sx={{width: '100%'}} direction="row">
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Total tax paid by company <Typography variant='caption'>[3]</Typography></Typography>
                                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{taxComputation.totalCompanyTax.toFixed(2)}</Typography>
                                </Stack>
                            </Grid>
                        </>
                    )
                }
            })()}

            <Grid xs={12} sx={{mt: 2}}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Total tax</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(taxComputation.totalTax ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>After-tax receipt</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>£&nbsp;{(taxComputation.afterTaxReceipt ?? 0).toFixed(2)}</Typography>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Stack sx={{width: '100%'}} direction="row">
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'left', mr: 'auto', fontWeight: 'bold'}}>Effective tax rate</Typography>
                    <Typography variant={'body1'} component={'span'} sx={{alignContent: 'right', ml: 'auto', fontWeight: 'bold'}}>{(100 * taxComputation.effectiveTaxRate).toFixed(2)} %</Typography>
                </Stack>
            </Grid>
            {(() => {
                if (taxComputation.tradingAs === "LimitedCompany") {
                    return (
                        <Grid xs={12}>
                            <div><Typography variant='caption'>[1] Corporation tax reduction from any salary paid and employer's national insurance contribution.</Typography></div>
                            <div><Typography variant='caption'>[2] Total cost to company includes salary, gross dividend and employer national insurance contributions.</Typography></div>
                            <div><Typography variant='caption'>[3] Total company tax includes corporation tax on dividend and employer national insurance contributions.</Typography></div>
                        </Grid>
                    )
                }
            })()}
        </Grid>
        {(() => {
            if (taxComputation.incomeTaxDue > 0 || taxComputation.totalNI > 0 || taxComputation.dividendTaxDue > 0 || taxComputation.corporationTaxDue > 0) {
                return <Grid container spacing={2} sx={{width: '100%', my: 2}}>
                    <Grid xs={12}>
                        <Typography variant={'subtitle1'} sx={{fontWeight: 'bold'}}>Calculation Breakdown</Typography>
                    </Grid>
                    {(() => {
                        if (taxComputation.incomeTaxDue > 0 || taxComputation.dividendTaxDue > 0) {
                            return <Grid xs={12}>
                                    <IncomeTaxCalculatorTable taxComputation={taxComputation}/>
                                </Grid>
                        }
                    })()}
                    {(() => {
                        if (taxComputation.totalNI > 0) {
                            return <Grid xs={12}>
                                    <NICalculatorTable taxComputation={taxComputation}/>
                                </Grid>
                        }
                    })()}
                    {(() => {
                        if (taxComputation.corporationTaxDue > 0) {
                            return <Grid xs={12}>
                                    <CorporationTaxCalculatorTable taxComputation={taxComputation}/>
                                </Grid>
                        }
                    })()}
                </Grid>
            }
        })()}
    </>
}


export default TaxCalculator;
