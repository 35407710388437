import React, {Dispatch, SetStateAction} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grid2 from '@mui/material/Unstable_Grid2';
import IncomeInputs from '../interfaces/IncomeInputs';
import produce from 'immer';
import getInputEventValue from '../functions/getInputEventValue';

const Grid = Grid2;


interface IncomeInputsFormProps {
    incomeInputs: IncomeInputs;
    setIncomeInputs: Dispatch<SetStateAction<IncomeInputs>>;
}

const IncomeInputsForm: React.FC<IncomeInputsFormProps> = (props) => {
    const {incomeInputs, setIncomeInputs} = props;

    return <Grid container spacing={4} sx={{width: '100%', my: 2}}>
        <Grid xs={12}>
            <TextField
                label="Other Salary (Gross)"
                helperText="Only include salary from other employments, not from your own limited company."
                type={"number"}
                value={incomeInputs.otherSalary}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.otherSalary = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Other Dividends"
                helperText="Only include dividend income from other share holdings, not from your own limited company."
                type={"number"}
                value={incomeInputs.otherDividends}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.otherDividends = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Savings Income"
                helperText="Income from UK bank interest"
                type={"number"}
                value={incomeInputs.savingsIncome}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.savingsIncome = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Property Income"
                helperText="Income from UK property rentals (after expenses if not claiming property allowance)"
                type={"number"}
                value={incomeInputs.propertyIncome}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.propertyIncome = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Capital Gains From Residential Property"
                helperText="Capital gains from residential property"
                type={"number"}
                value={incomeInputs.capitalGainsRedidentialProperty}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.capitalGainsRedidentialProperty = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Capital Gains From Other Assets"
                helperText="Capital gains from non residential property assets"
                type={"number"}
                value={incomeInputs.capitalGainsOther}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.capitalGainsOther = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Foreign Taxes Paid or Witheld"
                helperText="Foreign taxes already paid or witheld to be deducted from any tax due"
                type={"number"}
                value={incomeInputs.foreignTaxesPaid}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.foreignTaxesPaid = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
        <Grid xs={12}>
            <TextField
                label="Gift Aid Contributions"
                helperText="Charitable donations made with Gift Aid"
                type={"number"}
                value={incomeInputs.giftAidContributions}
                onChange={(event) => setIncomeInputs(produce((draft) => {draft.giftAidContributions = getInputEventValue(event)}))}
                InputProps={{
                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
                sx={{width: '100%'}}
            />
        </Grid>
    </Grid>

}

export default IncomeInputsForm;
